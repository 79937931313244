/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
				/*
		  WebFontConfig = {
		  	google: { families: [ 'Lato:400,300,700,900:latin' ] }
		 };
		          (function() {
		            var wf = document.createElement('script');
		            wf.src = '//ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
		            wf.type = 'text/javascript';
		            wf.async = 'true';
		            var s = document.getElementsByTagName('script')[0];
		            s.parentNode.insertBefore(wf, s);
		          })();
			*/
        // JavaScript to be fired on all pages


		$('.navbar-toggle').removeAttr('data-toggle');

		var $menu = $("#main-menu").clone();
		var $menu_top = $("#menu-top-menu").clone();
		$menu.attr( "id", "mobile-menu" );
		$menu.attr( "class", "hidden-lg hidden-md" );
		$menu_top.find('li').addClass('menu-top');
		$menu_top.children().prependTo($menu.find("#menu-menu"));
		$menu.find('ul').removeClass('dropdown-menu');
		$menu.find('ul').removeClass('nav navbar-nav');
		$menu.mmenu({
			slidingSubmenus	: true,
			onClick: {
				close:false
			}
		   // options
		}, {
		   // configuration
		   clone: true
		});
		var API = $menu.data( "mmenu" );
		$(window).resize(function() {
      if(API) {
        API.close();
      }
		});
    /*
		$container = $('.isotope');
		$container.imagesLoaded( function () {
			console.log("imagesloaded");
			$container.isotope({
		    	columnWidth: '.item',
		    	itemSelector: '.item',
			    masonry: {
			       	columnWidth: '.item',
					itemSelector: '.item'
			     }
		  	});
		});
    */
		$(".small-size").click(function(event){
			event.preventDefault();
		    //$("h1").animate({"font-size":"24px"});
		    //$("h2").animate({"font-size":"16px"});
		    $("p").animate({"font-size":"14px", "line-height":"1.428571429em"});

		});

		$(".medium-size").click(function(event){
			event.preventDefault();
		    //$("h1").animate({"font-size":"36px"});
		    //$("h2").animate({"font-size":"24px"});
		    $("p").animate({"font-size":"16px", "line-height":"1.428571429em"});

		});

		$(".big-size").click(function(event){
			event.preventDefault();
		    //$("h1").animate({"font-size":"48px"});
		    //$("h2").animate({"font-size":"30px"});
		    $("p").animate({"font-size":"18px", "line-height":"1.428571429em"});

		});

		$( "a" ).click(function() {
			$("a").removeClass("selected");
		  	$(this).addClass("selected");
		});
		$hash = $('.gform_validation_error').parent().attr('id');
		if($hash) {
			$('a[href="#' + $hash + '"]').click();
		}
		// Seleziona le voci parent del menu quando la pagina attiva è un contenuto associato a una categoria
		// ma non direttamente al menu
		$('.dropdown-menu li.current-post-parent').parents('li').addClass('current-menu-ancestor');


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
		  jQuery('#news-evidenza').slippry({
		    // general elements & wrapper
		    slippryWrapper: '<div class="sy-box news-slider" />', // wrapper to wrap everything, including pager
		    elements: 'article', // elments cointaining slide content

		    // options
		    adaptiveHeight: false, // height of the sliders adapts to current
		    captions: false,
			pager:false,
			controls:false,

		    // transitions
		    transition: 'horizontal', // fade, horizontal, kenburns, false
		    speed: 1400,
		    pause: 4000,

		    // slideshow
		    autoDirection: 'prev'
		  });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
